@use './utility' as utils;

// CSS CUSTOM
@use './syncfusion-custom/buttons/buttons' as *;
@use './syncfusion-custom/grid/grid' as *;
@use './syncfusion-custom/inputs/inputs' as *;
@use './syncfusion-custom/layouts/splitter' as *;

@import url(https://cdn.syncfusion.com/ej2/27.2.2/material3.css?_gl=1*1o4y15v*_ga*MTAwOTk3MjI5MS4xNzEyMTgwMTAw*_ga_41J4HFMX1J*MTczMTc3MjM4My4xNi4xLjE3MzE3NzQyNTIuMC4wLjA.);

:root {
  --color-sf-primary: 41, 121, 255;
  --color-sf-secondary: 117, 117, 117;
  --color-sf-primary-container: 41, 121, 255;
  --color-sf-scrim: 67, 67, 67;
  --color-sf-on-surface: 73, 69, 78;
}

// Icona "colore carattere" richtext editor
.e-rte-toolbar .e-rte-font-color::before, .e-rte-dropdown-popup .e-rte-font-color::before {
  content: "\e34c";
}

th.header-text-center {
  padding: 0 10px !important;
}
td.header-text-center {
  padding: 10px !important;
}
td.e-gridchkbox {
  padding: 0 !important;
  .e-checkbox-wrapper {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .e-label {
      display: none !important;
    }
  }
}

/* rimosso accapo nei filtri in colonna tra check e nome filtro */
li .e-cclist .e-cc > .e-checkbox-wrapper, .e-css.e-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
  top: unset !important;
}

th.e-headercell {
  &.hide-sort{
    .e-sortfilterdiv {
      display: none !important;
    }
  }
  .e-sortfilterdiv {
    padding: .2rem 0 !important;
  }
  .e-headerchkcelldiv {
    padding: 0 2px 0 0 !important;
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    .e-label {
      display: none !important;
    }
  }
}

.e-gridpager {
  .e-pagesizes {
    position: absolute !important;
    margin-top: -1px !important;
    .e-pagerdropdown {
      height: auto !important;
      margin-top: 0 !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 90px;
    }
    .e-pagerconstant {
      display: none !important;
      min-width: 9rem !important;
      position: absolute !important;
      justify-content: center !important;
      height: 3rem !important;
      @include utils.laptop() {
        display: inline-flex !important;
      }
      .e-constant {
        display: flex !important;
        height: 100% !important;
        align-items: center !important;
      }
    }
  }
}

.e-grid {
  .e-toolbar {
    .e-toolbar-item {
      .e-btn-icon {
        display: none;
      }
    }
  }
}

.e-dialog {
  border-radius: .5rem;
}

.e-dialog .e-dlg-content {
  white-space: pre-line;
}

/* DropDownList SyncFusion */
/*.e-dropdownbase {


  .e-list-item.e-item-focus {
    background-color: utils.$base-secondary;
  }

  .e-list-item.e-hover {
    background-color: utils.$base-secondary;
    color: utils.$base-primary;
  }
}*/

.e-contextmenu.e-menu-parent {
  z-index: 99999 !important;
}

.e-input-group, .e-input-group.e-control-wrapper,
.e-float-input, .e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  background: white;
}

.e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::before, .e-outline.e-valid-input:not(.e-input-focus) label.e-float-text::after, .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::before, .e-outline.e-float-input.e-valid-input:not(.e-input-focus) label.e-float-text::after, .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::before, .e-outline.e-float-input.e-control-wrapper.e-valid-input:not(.e-input-focus) label.e-float-text::after {
  border-width: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.38);
}

.e-excelfilter {
  &.e-dialog {
    .e-dlg-content {
      padding: .5rem;
    }
  }

  .e-searchbox {
    @apply tw-px-2;
  }

  // Pezza popup filtro colonna griglia per la sovrapposizione delle label
  .e-ftrchk {
    @apply tw-overflow-hidden tw-p-2 tw-pl-3;
  }

  .e-spinner {
    .e-label.e-checkboxfiltertext {
      @apply tw-truncate tw-w-full tw-h-[16px] #{!important};
    }
  }
}

.e-excelfilter .e-contextmenu-wrapper ul li,
.e-excelfilter .e-contextmenu-container ul li {
  display: flex;
  align-items: center;
  border-radius: .5rem;

  span {
    height: auto;
    line-height: inherit;

    &.e-icons.e-caret {
      margin-right: 0;
    }
  }
}

.e-grid th.e-headercell[aria-sort=ascending] .e-headertext,
.e-grid th.e-headercell[aria-sort=descending] .e-headertext,
.e-grid th.e-headercell[aria-sort=ascending] .e-sortfilterdiv,
.e-grid th.e-headercell[aria-sort=descending] .e-sortfilterdiv {
  color: inherit;
}

.e-grid td.e-updatedtd {
  background-color: inherit;
}

.e-grid th.e-headercell.required .e-headertext {
  @apply tw-text-red-500;
}

// Nuove regole post material 3 css
.e-list-item.e-active {
  color: white !important;
}

.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
  color: white !important;
}

.e-acrdn-root {
  &:first-child {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }
  &:last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  box-shadow: none !important;
  background: #eee !important;
}

.e-accordion .e-acrdn-item.e-selected.e-select>.e-acrdn-header {
  background: #eee !important;
}

.e-pager {
  &:not(.e-gridpager) {
    opacity: .87 !important;
  }
  border-color: #d7d7d7;
}

.e-dialog {

  .e-footer-content {
    padding: .5rem !important;
  }

  &:not(.e-confirm-dialog, .e-alert-dialog) {
    .e-footer-content {
      padding: .25rem !important;
    }

    .e-dlg-header-content {
      padding: .5rem 1.25rem !important;
    }
  }
  .e-footer-content {
    .e-btn {
      box-shadow: none !important;
      padding: .75rem !important;
    }
    .e-btn:not(.e-primary) {
      @apply tw-text-gray-600 #{!important};

      &:hover.e-flat:not([DISABLED]):not(.e-success):not(.e-danger):not(.e-warning):not(.e-info) {
        @apply tw-bg-gray-600/10 #{!important};
      }
    }
  }
}

.e-pagerdropdown {
  span {
    height: 2.82rem;
  }
}

.e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
  margin: 0 20px 0 0 !important;
}

.list-box .btn-trash button {
  box-shadow: none !important;
}

.e-contextmenu-wrapper ul.e-contextmenu,
.e-contextmenu-container ul.e-contextmenu {
  padding: .5rem;
  border-radius: .5rem;
}

.e-contextmenu-wrapper ul .e-menu-item:not(.e-excel-descending, .e-excel-ascending, .e-excel-separator, .e-separator),
.e-contextmenu-container ul .e-menu-item {
  padding: .5rem .75rem;
  line-height: normal;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.e-contextmenu-wrapper ul li.e-separator.e-menu-item.e-excel-separator {
  margin: .5rem 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused,
.e-contextmenu-container ul .e-menu-item.e-focused {
  color: #1e293b;
  background-color: #f1f5f9;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused .e-menu-icon,
.e-contextmenu-container ul .e-menu-item.e-focused .e-menu-icon,
.e-contextmenu-wrapper ul .e-menu-item.e-selected .e-caret,
.e-contextmenu-container ul .e-menu-item.e-selected .e-caret {
  color: #1e293b;
}

.e-contextmenu-container ul .e-menu-item.e-selected {
  background-color: #ccdfff;
  color: #2367d9;
}

.e-footer-content {
  flex-direction: row-reverse;
  display: flex!important;
}
.e-treeview {
  overflow: auto;
}