@use "../../utility" as utils;

.e-grid {
  box-shadow: 0 0 20px -15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: 100%;

  &[allowgrouping=true] {
    border-top: 1px solid #e0e0e0;

    .e-toolbar {
      border-top-left-radius: unset; //0.54em;
      border-top-right-radius: unset;
      border-left: unset;
      border-right: unset;
      background-color: white;

      .e-toolbar-items {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }
    }
  }

  .e-toolbar {
    .e-toolbar-items {
      background: white;
    }
  }

  .e-groupdroparea {
    background: none;
    border: none;
    margin: 0 auto;
    width: calc(100% - 6em);
    text-overflow: ellipsis;
    white-space: nowrap;

    &.e-grouped {
      background: none;
    }

    .e-groupheadercell {
      margin-left: 0;
    }
  }

  &.e-gridhover tr[role='row']:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover tr[role='row']:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-rtl .e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
  .e-grid.e-gridhover tr[role='row']:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    color: utils.$base-primary;
    background-color: white;
    cursor: pointer;
  }

  tr .e-rowcell.e-active {
    color: utils.$base-primary;
    background-color: #eeeeee;
    cursor: pointer;
  }

  .e-gridheader {
    thead {
      .e-icons:not(.e-check):not(.e-stop) {
        font-size: 1.25rem;
        padding: .25rem;
      }
    }
  }

  .e-toolbar {
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    //border: 1px solid #e0e0e0;
    border-bottom: none;
    background: white;
    min-height: 50px;

    .e-toolbar-items {
      background-color: utils.$base-secondary;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      /*margin: 0 2.5em;*/
      width: 100%;

      &.e-tbar-pos {

        .e-toolbar-right {
          /*top: -2px;*/
          margin-right: 1.5em;
        }

        .e-toolbar-left {
          margin-left: 1.5em;
        }
      }

      .e-toolbar-item {
        margin: 0 5px;

        &.e-cc.e-ccdiv.e-cc-toolbar {
          padding: 3.5px;
        }

        &.e-overlay{
          background-color: transparent;
        }

        .e-tbar-btn.e-btn {
          height: 34px;
          padding: 0 1em;
          border: 1px solid lightgrey;
          background-color: utils.$base-secondary;
          border-radius: 5px;
          margin: auto;

          .e-btn-icon.e-columnchooser-btn {
            display: none;
          }

          &:hover {
            background-color: utils.$base-primary;
            border-color: utils.$base-primary;

            .e-tbar-btn-text {
              color: utils.$base-secondary;
            }

            .e-columnchooser-btn {
              color: utils.$base-secondary;
            }
          }
        }
      }
    }
  }
}

/* Pezza per checkbox doppia nel column chooser a finestra piccola */
.e-grid-popup {
  .e-ccdlg .e-chk-hidden {
    -moz-appearance: none;
    height: 1px;
    opacity: 0;
    width: 1px;
  }
  .e-ccdlg  li.e-cclist.e-cc {
    padding: 9px 0;
  }
}

.e-grid .e-ccdlg li.e-cclist.e-cc-selectall, .e-grid-popup .e-ccdlg li.e-cclist.e-cc-selectall {
  padding-top: 0 !important;
}

.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  padding: 0 .5rem !important;
}

.e-grid .e-summarycell, .e-grid .e-groupcaption, .e-grid .e-indentcell, .e-grid .e-recordplusexpand, .e-grid .e-recordpluscollapse {
  background: #fafafa;
}

.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
  box-shadow: none !important;
}

.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background: unset !important;
}

.e-grid .e-headercelldiv,
.e-grid .e-rowcell {
  font-size: 13px;
}

.e-grid .e-groupdroparea {
  text-align: left;
  .e-icons.e-focused:not(:hover) {
    background-color: unset !important;
  }
}

.e-emptyrow {
  position: absolute;
}

.e-grid .e-ccdlg .e-checkbox-wrapper .e-frame,
.e-grid-popup .e-ccdlg .e-checkbox-wrapper .e-frame {
  min-width: 14px;
}

[aria-sort="none"] {
  .e-headercelldiv {
    padding-right: 2px !important;
  }
  .e-sortfilterdiv {
    display: none;
  }
}
