@use "../../utility" as utils;

.e-btn {
  min-height: 36px;
  border-radius: 4px;
  text-transform: unset;
  &.e-secondary:not(:disabled) {
    background: utils.$base-accent;
    color: white;
  }
  &.e-primary:disabled,
  &.e-secondary:disabled {
    cursor: not-allowed;
  }
}

.e-css.e-btn.e-primary:disabled {
  cursor: not-allowed;
}

.e-acrdn-content {
  padding: .75rem 0 !important;
}

.e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  box-shadow: none;
}